import React, { FC, ElementType } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { Typography, Paper, Container, CircularProgress } from '@material-ui/core';
import { QuestionData, QuestionType, RenderingHint } from './types';
import GridQuestion from './gridQuestion';
import ReorderQuestion from './reorderQuestion';
import FixedLayoutQuestion from './fixedLayoutQuestion';
import CategorizationQuestion from './categorizationQuestion';
import { changeAnswer } from './slice';
import { questionsDataSelector, resultsSelector } from './selectors';

type QuestionSetProps = {
    disabled?: boolean
}

const QuestionSet: FC<QuestionSetProps> = ({ disabled }) => {
    const classes = useStyles();

    const questions = useSelector(questionsDataSelector, R.equals);
    const results = useSelector(resultsSelector);

    const dispatch = useDispatch();

    if (questions.length === 0) {
        return (
            <Container className={classes.loadingContainer}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container>
            {questions.map((question, index) => {
                const QuestionComponent = decideQuestionComponent(question);

                return (
                    <Paper elevation={5} className={classes.question} key={index}>
                        <Typography variant="h6">Question {question.sequenceNumber}</Typography>
                        {QuestionComponent ?
                            <QuestionComponent question={question} selection={results[question.id!]}
                                onAnswerChange={(newAnswer: any) => {
                                    if (!disabled && newAnswer) {
                                        dispatch(changeAnswer({ questionId: question.id, answerIds: newAnswer }))
                                    }
                                }} />
                            : null}
                    </Paper>
                );
            })}
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    question: {
        padding: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            maxWidth: '75%'
        }
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    }
}));

QuestionSet.defaultProps = {
    disabled: false
}

export function decideQuestionComponent(question: QuestionData): ElementType | undefined {
    if (question.type === QuestionType.Single) {
        if (question.renderingHint.includes(RenderingHint.Grid)) {
            return GridQuestion;
        } else if (question.renderingHint.includes(RenderingHint.Ordering)) {
            return ReorderQuestion;
        } else if (question.renderingHint.includes(RenderingHint.Categorization)) {
            return CategorizationQuestion;
        } else {
            return FixedLayoutQuestion;
        }
    } else {
        if (question.renderingHint.includes(RenderingHint.Grid)) {
            return GridQuestion;
        }
    }
}

export default QuestionSet;
