import React, { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Tooltip, IconButton, Container, CircularProgress, Card, CardContent, CardActions, Button } from "@material-ui/core";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon, Done as DoneIcon, Publish as PublishIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import Logo from '../Logo';
import Firebase from 'firebase/app';
import { RootState } from '../store';

const SectionListing: FC = () => {
    const classes = useStyles();

    const { assessmentId } = useParams<{ assessmentId: string }>();

    const [sections, setSections] = useState([] as {id: string, name: string}[]);
    const [completedSections, setCompletedSections] = useState<string[] | undefined>(undefined);

    // @ts-ignore
    const userId = useSelector<RootState>(state => state.firebase.auth.uid);

    useEffect(() => {
        async function fetchSections() {
            const doc = Firebase.firestore().doc(`assessments/${assessmentId}`);

            try { 
                setSections((await doc.get({ source: "cache" })).get('sections'))
            } catch {
                setSections((await doc.get()).get('sections'))
            }
        }

        async function fetchCompletedSections() {
            if (!userId) { return; }

            const completedSections = await Firebase.firestore().doc(`userProfiles/${userId}`).collection(assessmentId).get();

            setCompletedSections(completedSections.docs.map(doc => doc.id));
        }

        fetchSections();
        fetchCompletedSections();
    }, [assessmentId, userId])

    const history = useHistory();
    const [submitDialogOpen, setSubmitDialogOpen] = useState(false);

    return (
        <>
            <Helmet>
                <title>Section Listing</title>
            </Helmet>
            <header>
                <AppBar position="static">
                    <Toolbar>
                        <Tooltip title="Back">
                            <IconButton edge="start" className={classes.backButton} color="inherit" component={Link} to={`/assessment-listing`}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Tooltip>
                        <Logo />
                        <Typography variant="h6" className={classes.title}>Sections</Typography>
                        <Button color="secondary"
                            variant="contained" startIcon={<PublishIcon />} onClick={async () => {
                                if (!sections.every(section => completedSections?.includes(section.id))) {
                                    setSubmitDialogOpen(true);
                                    return;
                                }

                                await Firebase.firestore().doc(`userProfiles/${userId}/${assessmentId}/complete`).set({ complete: true, time: Firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
                                
                                history.push('/assessment-listing');
                            }}>
                            Submit Assessment
                        </Button>
                    </Toolbar>
                </AppBar>
            </header>
            <Dialog open={submitDialogOpen}>
                <DialogContent>
                    You've not completed all the sections.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSubmitDialogOpen(false)} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <main className={classes.listing}>
                {sections?.length > 0 && completedSections ? (<>
                    {sections.map(sectionObj => {
                        const isCompleted = completedSections.includes(sectionObj.id);

                        return (
                            <Card key={sectionObj.id} className={classes.sectionCard} elevation={3}>
                                <CardContent>
                                    <Typography variant="h5" color="textPrimary">
                                        {sectionObj.name}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button variant="outlined" color="secondary"
                                        disabled={isCompleted}
                                        component={Link} to={`/assessment/${assessmentId}/${sectionObj.id}/${sectionObj.name}`} size="medium">
                                        {!isCompleted ? "Start" : "Completed"}
                                    </Button>
                                    {isCompleted ? <DoneIcon className={classes.doneIcon} fontSize="large" /> : null}
                                </CardActions>
                            </Card>
                        );
                    })}
                </>): <Container className={classes.loadingContainer}><CircularProgress /></Container>}
            </main>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    listing: {
        padding: theme.spacing(3),
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1
    },
    sectionCard: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        display: 'inline-block',
        minWidth: '20%'
    },
    doneIcon: {
        color: theme.palette.success.main,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
}));

export default SectionListing;