import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Firebase from "firebase/app";
import "firebase/firestore"
import { AppBar, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, LinearProgress, makeStyles, Paper, Select, TextField, Toolbar, Typography } from '@material-ui/core';
import { Assessment as AssessmentIcon, Edit as EditIcon, LockOpen as LockOpenIcon } from "@material-ui/icons";
import { DataGrid, FilterInputValueProps, GridCellParams, GridFilterItem, GridToolbar } from '@material-ui/data-grid';
import { useHistory } from "react-router-dom";
import ChipInput from "material-ui-chip-input";
import fileDownload from "js-file-download";

const Admin: FC = () => {
    const classes = useStyles();

    const [completed, setCompleted] = useState([] as {[property: string]: any}[]);
    const [completedIdx, setCompletedIdx] = useState({} as {[userId: string]: number})
    const [assessmentNames, setAssessmentNames] = useState({} as {[assessment: string]: string});

    const assessment = "2eaaccc9-9e03-462c-824b-9e625bf9a33c";

    useEffect(() => {
        async function getCompleteAssessments() {
            const assessmentNames = Object.fromEntries(
                (await Firebase.firestore().collection("assessments").get())
                    .docs.map(doc => [doc.id, doc.get("name")])
            );

            setAssessmentNames(assessmentNames);

            const completedUids = (await Firebase.firestore().collectionGroup(assessment)
                    .where('complete', '==', true).orderBy("time", "desc").get()).docs.map(doc => doc.ref.parent.parent?.id);

            const completed = await Promise.all(
                completedUids.map(async (userId, idx) => {
                    const userProfileDoc = Firebase.firestore().collection("userProfiles").doc(userId);
                    const profile = (await userProfileDoc.get()).data();
                    const { generated, reportData } = (await userProfileDoc.collection(assessment).doc("complete").get()).data() ?? {};

                    let name = assessmentNames[assessment];

                    setCompletedIdx(completedIdx => ({...completedIdx, [userId!]: idx}));

                    return {
                        id: userId!,
                        assessmentName: name,
                        generated,
                        reportData,
                        ...profile,
                    }
                })
            )

            setCompleted(completed);
        }

        getCompleteAssessments();
    }, []);

    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [selection, setSelection] = useState(undefined as any);
    const selectedUser = useMemo(() => completed.find(user => user.id == selection?.[0]), [selection, completed]);
    const history = useHistory();

    const [unlockDialogOpen, setUnlockDialogOpen] = useState(false);
    const [numUnlockCodes, setNumUnlockCodes] = useState<number | undefined>(1);
    const [unlockEnabledAssessments, setUnlockEnabledAssessments] = useState<string[]>([]);
    const [generatingCodes, setGeneratingCodes] = useState(false);
    const [filterModel, setFilterModel] = useState({
        items: [{ columnField: 'generated', operatorValue: '=', value: "false" } as GridFilterItem]
    });

    return (
        <>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <header>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>Admin</Typography>
                        <Button color="secondary" variant="contained"
                            onClick={() => setUnlockDialogOpen(true)}
                            startIcon={<LockOpenIcon />} className={classes.unlockCodeButton}>
                            Generate Unlock Codes
                        </Button>
                        <Button color="secondary" variant="contained"
                            startIcon={selectedUser && selectedUser.generated ? <EditIcon /> : <AssessmentIcon />}
                            onClick={() => {
                                setReportDialogOpen(true);

                                if (!selection) {
                                    return;
                                }

                                const userProfile =
                                    Firebase.firestore().collection("userProfiles").doc(selection[0]);

                                const assessmentCol = userProfile.collection(assessment);

                                if (selection) {
                                    Promise.all([
                                        assessmentCol.doc("ed5475af-026f-43dd-ab59-3733e219cd5e").get(), // IQ
                                        assessmentCol.doc("4063f034-a1d7-423d-a97f-67015754e329").get(), // Interest
                                        assessmentCol.doc("bd53d50a-0269-445e-9b99-a9e423ba116a").get(), // Personality traits
                                        assessmentCol.doc("9a5c8d78-e9e6-4082-b84d-94f15082f340").get(), // Clerical
                                        assessmentCol.doc("d08b6112-1e34-40c8-9000-8ef062665fda").get(), // Reasoning
                                        assessmentCol.doc("b65c0cc9-ada0-4bab-9299-7d4d23a4e188").get(), // Mechanical
                                        assessmentCol.doc("1bb944e6-f48c-40b3-9c05-0bebe65c6ef3").get(), // Closure
                                        assessmentCol.doc("777523b8-9d6f-4f6c-b18c-69822fdad292").get(), // Numerical
                                        assessmentCol.doc("d6f3a6db-3e3b-4f29-bdf8-200921e5c306").get(), // Verbal
                                        assessmentCol.doc("9485a169-f0a2-426a-bf0e-150b867b13ab").get(), // Spatial
                                    ]).then(([iq, int, per, cler, reason, mech, clos, numer, verbal, spatial]) => {
                                        const intel = iq.get("finalScores")?.["TOTAL"] as number ?? 5;
                                        const inter = int.get("finalScores") as {[property: string]: number};
                                        const pers = per.get("finalScores") as {[property: string]: number};
                                        const cl = cler.get("finalScores")?.["TOTAL"] as number ?? 0;
                                        const ra = reason.get("finalScores")?.["TOTAL"] as number ?? 0;
                                        const ma = mech.get("finalScores")?.["TOTAL"] as number ?? 0;
                                        const ca = clos.get("finalScores")?.["TOTAL"] as number ?? 0;
                                        const na = numer.get("finalScores")?.["TOTAL"] as number ?? 0;
                                        const va = verbal.get("finalScores")?.["TOTAL"] as number ?? 0;
                                        const sa = spatial.get("finalScores")?.["TOTAL"] as number ?? 0;

                                        history.push('/report', {
                                            uid: selection[0],
                                            username: selectedUser?.name,
                                            iq: intel,
                                            interestData: [
                                                inter?.["FA"] ?? 0, inter?.["LW"] ?? 0,
                                                inter?.["SC"] ?? 0, inter?.["M"] ?? 0,
                                                inter?.["AG"] ?? 0, inter?.["TECHNICAL"] ?? 0,
                                                inter?.["CR"] ?? 0, inter?.["OD"] ?? 0,
                                                inter?.["SP"] ?? 0, inter?.["HHW"] ?? 0
                                            ],
                                            behaviouralTraitsData: [
                                                pers?.["A"] ?? 0, pers?.["B"] ?? 0,
                                                pers?.["C"] ?? 0, pers?.["D"] ?? 0,
                                                pers?.["E"] ?? 0, pers?.["F"] ?? 0,
                                                pers?.["G"] ?? 0, pers?.["H"] ?? 0,
                                                pers?.["I"] ?? 0, pers?.["J"] ?? 0,
                                                pers?.["O"] ?? 0, pers?.["Q2"] ?? 0,
                                                pers?.["Q3"] ?? 0, pers?.["Q4"] ?? 0
                                            ],
                                            abilityData: [va, na, sa, ca, ma, cl, ra],
                                            reportData: selectedUser?.generated ? selectedUser.reportData : undefined,
                                        })
                                    })
                                }
                            }}>
                            {selectedUser && selectedUser.generated ? "Edit Report" : "Generate Report"}
                        </Button>
                    </Toolbar>
                </AppBar>
            </header>
            <Dialog open={reportDialogOpen}>
                <DialogTitle>{selection ? "Generating..." : "No selection"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selection ? <CircularProgress /> : "You need to select a user to generate a report for."}
                    </DialogContentText>
                </DialogContent>
                {!selection ? <DialogActions>
                    <Button onClick={() => setReportDialogOpen(false)} color="primary">
                        Ok
                    </Button>
                </DialogActions> : null}
            </Dialog>
            <Dialog open={unlockDialogOpen} onClose={() => setUnlockDialogOpen(false)}>
                <DialogTitle>Generate Assessment Unlock Codes</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Generate
                        <TextField
                            type="number"
                            size="small"
                            className={classes.unlockNumberInput}
                            value={numUnlockCodes}
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={(event) => {
                                if (event.target.value) {
                                    setNumUnlockCodes(Number(event.target.value));
                                } else {
                                    setNumUnlockCodes(undefined);
                                }
                            }} />
                        code{numUnlockCodes && numUnlockCodes > 1 ? "s" : ""} that
                        {numUnlockCodes && numUnlockCodes > 1 ? " each unlock " : " unlocks "}the following
                        assessment{unlockEnabledAssessments.length == 1 ? "" : "s"}:
                    </DialogContentText>
                    <FormGroup>
                        {assessmentNames && Object.keys(assessmentNames).length > 0 ? Object.entries(assessmentNames).map(([id, name]) => (
                            <FormControlLabel
                                label={name}
                                control={
                                    <Checkbox
                                        checked={unlockEnabledAssessments.includes(id)}
                                        onChange={event => {
                                            if (event.target.checked) {
                                                setUnlockEnabledAssessments(unlocked => [...unlocked, id]);
                                            } else {
                                                setUnlockEnabledAssessments(unlocked => unlocked.filter(assessment => assessment !== id))
                                            }
                                        }}
                                        name={id} />
                                } />
                        )) : <CircularProgress />}
                    </FormGroup>
                </DialogContent>
                {generatingCodes ? <LinearProgress color="secondary" /> : null}
                <DialogActions>
                    <Button color="primary" disabled={!numUnlockCodes || generatingCodes} onClick={async () => {
                        if (!numUnlockCodes) {
                            return;
                        }

                        setGeneratingCodes(true);

                        const codes = [];
                        const promises = [];

                        for (let i = 0; i < numUnlockCodes; i++) {
                            const code = 
                                Math.round((Math.pow(36, 11) - Math.random() * Math.pow(36, 10)))
                                    .toString(36).slice(1).toUpperCase();

                            codes.push(code);
                            promises.push(Firebase.firestore().collection("activationCodes").doc(code).set({
                                enabledAssessments: unlockEnabledAssessments,
                                generatedTimestamp: Firebase.firestore.FieldValue.serverTimestamp()
                            }));
                        }

                        await Promise.all(promises);
                        fileDownload(codes.join("\n"), "generatedAssessmentCodes.txt");

                        setGeneratingCodes(false);
                        setUnlockDialogOpen(false);
                        setNumUnlockCodes(1);
                        setUnlockEnabledAssessments([]);
                    }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <main style={{ height: '100%', width: '100%' }}>
                <Paper elevation={3} className={classes.grid}>
                        {completed.length == 0 ? <CircularProgress /> : <DataGrid checkboxSelection
                            filterModel={filterModel}
                            onFilterModelChange={params => setFilterModel(params.filterModel)}
                            components={{
                                Toolbar: GridToolbar
                            }}
                            disableMultipleSelection rows={completed} columns={[
                            { field: 'name', headerName: 'Name', flex: 0.25 },
                            { field: 'assessmentName', headerName: 'Assessment', flex: 0.25 },
                            {
                                field: 'categories',
                                headerName: 'Categories',
                                flex: 0.25,
                                renderCell: (params: GridCellParams) => {
                                    return (
                                        <div className={classes.categoryChips}>
                                            {Object.entries(params.value ?? {}).map(([key, value], idx) => <Chip key={idx} label={`${key}: ${value}`} />)}
                                        </div>
                                    );
                                },
                                filterOperators: [
                                    {
                                        label: 'all of',
                                        value: 'all of',
                                        getApplyFilterFn: filterItem => {
                                            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                                                return null;
                                            }

                                            if (filterItem.value.length === 0) {
                                                return () => true;
                                            }

                                            const targetObj = JSON.parse(filterItem.value).map((s: string) => s.split(':'));

                                            console.log(targetObj);

                                            return (params) =>
                                                targetObj
                                                    // @ts-ignore
                                                    .every(([key, value]: string[]) => params.value[key]?.trim().toLowerCase() === value?.trim().toLowerCase());
                                        },
                                        InputComponent: ChipFilterInput,
                                    },
                                    {
                                        label: 'any of',
                                        value: 'any of',
                                        getApplyFilterFn: filterItem => {
                                            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                                                return null;
                                            }

                                            if (filterItem.value.length === 0) {
                                                return () => true;
                                            }

                                            const targetObj = JSON.parse(filterItem.value).map((s: string) => s.split(':'));

                                            console.log(targetObj);

                                            return (params) =>
                                                targetObj
                                                    // @ts-ignore
                                                    .some(([key, value]: string[]) => params.value[key]?.trim().toLowerCase() === value?.trim().toLowerCase());
                                        },
                                        InputComponent: ChipFilterInput,
                                    }
                                ]
                            },
                            {
                                field: 'generated',
                                headerName: 'Status',
                                flex: 0.25,
                                valueFormatter: (params) => params.value ? 'Generated' : 'Completed',
                                filterOperators: [
                                    {
                                        label: 'equals',
                                        value: '=',
                                        getApplyFilterFn: filterItem => {
                                            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                                                return null;
                                            }

                                            return params => filterItem.value == "all" || (Boolean(params.value) === (filterItem.value === "true"));
                                        },
                                        InputComponent: ({ item, applyValue }) => (
                                            <FormControl>
                                                <InputLabel shrink>Value</InputLabel>
                                                <Select native value={item.value}
                                                    onChange={(event) => applyValue({ ...item, value: event.target.value as string})}>
                                                    <option value="false">Completed</option>
                                                    <option value="true">Generated</option>
                                                    <option value="all">All</option>
                                                </Select>
                                            </FormControl>
                                        )
                                    }
                                ]
                            }
                        ]} pageSize={20}
                        onSelectionModelChange={(newSelection) => setSelection(newSelection.selectionModel)}
                        selectionModel={selection} />}
                </Paper>
            </main>
        </>
    );
}

const ChipFilterInput: FC<FilterInputValueProps> = ({ item, applyValue }) => {
    const arrayValue = JSON.parse(item.value ?? "[]");

    return (
        <FormControl>
            <InputLabel shrink>Values</InputLabel>
            <ChipInput
                placeholder="Eg, gender: f"
                newChipKeys={['Enter', 'Tab']}
                value={arrayValue}
                onBeforeAdd={chip => chip.includes(":")}
                onAdd={chip => applyValue({ ...item, value: JSON.stringify([...arrayValue, chip]) })}
                onDelete={(_, index) => {
                    arrayValue.splice(index, 1);
                    applyValue({ ...item, value: JSON.stringify(arrayValue) })
                }} />
        </FormControl>
    );
}

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1
    },
    unlockCodeButton: {
        marginRight: theme.spacing(1)
    },
    unlockNumberInput: {
        width: '80px',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    grid: {
        height: '80vh',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    categoryChips: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5)
        }
    }
}));

export default Admin;