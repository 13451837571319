import React, { FC, useMemo, useState, useEffect } from "react";
import { CircularProgress, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Button, AppBar, Toolbar, Typography, Snackbar } from "@material-ui/core";
import { Update as UpdateIcon, Save as SaveIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from "@react-pdf/renderer";
import { Chart } from "chart.js";
import html2canvas from 'html2canvas';
import { useLocation, useHistory } from "react-router-dom";
import RichTextEditor from "react-rte";
import Firebase from "firebase/app";
import "firebase/firestore"
import { Alert } from "@material-ui/lab";

const Report: FC = () => {
    const classes = useStyles();
    const location = useLocation() as any;

    const history = useHistory();

    useEffect(() => {
        if (!location.state) {
            history.push("/admin");
        }
    }, [location.state])

    const { iq, interestData, behaviouralTraitsData, abilityData, uid, username, reportData } = location.state ?? {};

    const firstName = username.split(' ')[0];

    const [interestChartImage, setInterestChartImage] = useState<string | undefined>();

    useEffect(() => {
        const interestChart = new Chart('interestChart', {
            type: 'bar',
            data: {
                labels: ['FA - Fine Arts', 'L - Literature', 'Sc - Science', 'M - Medical', 'Ag - Agriculture', 'Tch - Technical', 'Cr - Crafts', 'Od - Outdoors', 'Sp - Sports', 'HW - Household Work'],
                datasets: [{
                    data: interestData,
                    backgroundColor: (context) => {
                        const value = context.dataset?.data![context.dataIndex!] as number;

                        if (value === 1) {
                            return "#f44336";
                        } else if (value === 2 || value === 3) {
                            return "#ffc400";
                        } else if (value >= 4 && value <= 6) {
                            return "#4484f2";
                        } else if (value === 7 || value === 8) {
                            return "#4caf50";
                        } else {
                            return "#357a38";
                        }
                    },
                }]
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: `Interests of ${firstName}`
                    },
                    legend: {
                        position: 'right',
                        labels: {
                            generateLabels: () => [
                                {
                                    text: "Low",
                                    fillStyle: "#f44336",
                                    datasetIndex: 0
                                },
                                {
                                    text: "Below Average",
                                    fillStyle: "#ffc400",
                                    datasetIndex: 0
                                },
                                {
                                    text: "Average",
                                    fillStyle: "#4484f2",
                                    datasetIndex: 0
                                },
                                {
                                    text: "Above Average",
                                    fillStyle: "#4caf50",
                                    datasetIndex: 0
                                },
                                {
                                    text: "High",
                                    fillStyle: "#357a38",
                                    datasetIndex: 0
                                }
                            ],
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            padding: 20
                        }
                    },
                    y: {
                        beginAtZero: true
                    }
                },
                animation: {
                    onComplete: () => {
                        setInterestChartImage(interestChart.toBase64Image());
                    }
                }
            }
        });
    }, [interestData]);

    const [intelligenceTable, setIntelligenceTable] = useState<string | undefined>();

    useEffect(() => {
        html2canvas(document.getElementById('intelligenceTable')!, { scale: 3 }).then(canvas => {
            setIntelligenceTable(canvas.toDataURL("image/png"));
        });
    }, []);

    const [behaviouralTraitsTable, setBehaviouralTraitsTable] = useState<string | undefined>();

    useEffect(() => {
        html2canvas(document.getElementById('behaviouralTraitsTable')!, { scale: 3 }).then(canvas => {
            setBehaviouralTraitsTable(canvas.toDataURL("image/png"));
        })
    }, []);

    // const behaviouralTraitsData = useMemo(() => [6, 8, 7, 5, 4, 4, 3, 4, 8, 3, 4, 5, 3, 4], []);
    const [behaviouralTraitsChartImage, setBehaviouralTraitsChartImage] = useState<string | undefined>();

    useEffect(() => {
        const behaviouralTraitsChart = new Chart('behaviouralTraitsChart', {
            type: 'bar',
            data: {
                labels: [
                    `A: Reserved vs Participating (${behaviouralTraitsData[0]})`,
                    `B: Dull vs Bright (${behaviouralTraitsData[1]})`,
                    `C: Emotionally Unstable vs Mature (${behaviouralTraitsData[2]})`,
                    `D: Stodgy vs Impatient (${behaviouralTraitsData[3]})`,
                    `E: Mild vs Assertive (${behaviouralTraitsData[4]})`,
                    `F: Serious vs Happy Go Lucky (${behaviouralTraitsData[5]})`,
                    `G: Casual vs Persevering (${behaviouralTraitsData[6]})`,
                    `H: Shy vs Thick Skinned (${behaviouralTraitsData[7]})`,
                    `I: Practical vs Sensitive (${behaviouralTraitsData[8]})`,
                    `J: Group vs Individualistic (${behaviouralTraitsData[9]})`,
                    `O: Secure vs Guilt-Prone (${behaviouralTraitsData[10]})`,
                    `Q2: Follower vs Leader (${behaviouralTraitsData[11]})`,
                    `Q3: Careless vs Self-Controlled (${behaviouralTraitsData[12]})`,
                    `Q4: Relaxed vs Tense (${behaviouralTraitsData[13]})`
                ],
                datasets: [{
                    data: behaviouralTraitsData,
                    barThickness: 75,
                    backgroundColor: 'blue',
                }, {
                    data: behaviouralTraitsData.map((data: number) => 9 - data),
                    barThickness: 75,
                    backgroundColor: 'green',
                }]
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: `${firstName}'s behavioural traits`
                    },
                    legend: {
                        display: false
                    }
                },
                aspectRatio: 1,
                indexAxis: 'y',
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            padding: 20,
                            autoSkip: false
                        }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: false
                    }
                },
                animation: {
                    onComplete: () => {
                        setBehaviouralTraitsChartImage(behaviouralTraitsChart.toBase64Image());
                    }
                }
            }
        });
    }, [behaviouralTraitsData]);

    const [abilityTable, setAbilityTable] = useState<string | undefined>();

    useEffect(() => {
        html2canvas(document.getElementById('abilityTable')!, { scale: 3 }).then(canvas => {
            setAbilityTable(canvas.toDataURL("image/png"));
        })
    }, []);

    // const abilityData = useMemo(() => [8, 7, 3, 8, 3, 4, 10], []);
    const [abilityChartImage, setAbilityChartImage] = useState<string | undefined>();

    useEffect(() => {
        const abilityChart = new Chart('abilityChart', {
            type: 'bar',
            data: {
                labels: ['VA - Verbal', 'NA - Numerical', 'SA - Spatial', 'CA - Closure', 'MA - Mechanical', 'CL - Clerical', 'RA - Reasoning'],
                datasets: [{
                    data: abilityData,
                    backgroundColor: (context) => {
                        const value = context.dataset?.data![context.dataIndex!] as number;

                        if (value >= 1 && value <= 3) {
                            return "#f44336";
                        } else if (value >= 4 && value <= 7) {
                            return "#4484f2";
                        } else {
                            return "#357a38";
                        }
                    },
                }]
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: `${firstName}'s abilities`
                    },
                    legend: {
                        position: "right",
                        labels: {
                            generateLabels: () => [
                                {
                                    text: "Low: 1-3",
                                    fillStyle: "#f44336",
                                    datasetIndex: 0
                                },
                                {
                                    text: "Average: 4-7",
                                    fillStyle: "#4484f2",
                                    datasetIndex: 0
                                },
                                {
                                    text: "High: 8-10",
                                    fillStyle: "#357a38",
                                    datasetIndex: 0
                                }
                            ],
                        }
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            padding: 20,
                            autoSkip: false
                        }
                    },
                    y: {
                        beginAtZero: true,
                        max: 12
                    }
                },
                animation: {
                    onComplete: () => {
                        setAbilityChartImage(abilityChart.toBase64Image());
                    }
                }
            }
        });
    }, [abilityData]);

    const [actionPlanTable, setActionPlanTable] = useState<string | undefined>();

    useEffect(() => {
        html2canvas(document.getElementById('actionPlanTable')!, { scale: 3 }).then(canvas => {
            setActionPlanTable(canvas.toDataURL("image/png"));
        })
    }, []);

    const [editorState, setEditorState] = useState(() => RichTextEditor.createValueFromString(reportData?.reportText ?? "", 'markdown'));
    const [reportText, setReportText] = useState(() => reportData?.reportText ?? "");

    let iqGrade, iqAbilityType;

    if (iq < 10) {
        iqGrade = 'V';
        iqAbilityType = 'Intellectual Defective';
    } else if (iq >= 10 && iq < 25) {
        iqGrade = 'IV';
        iqAbilityType = 'Below Average';
    } else if (iq >= 25 && iq < 75) {
        iqGrade = 'III';
        iqAbilityType = 'Average';
    } else if (iq >= 75 && iq < 95) {
        iqGrade = 'II';
        iqAbilityType = 'Above Average';
    } else {
        iqGrade = 'I';
        iqAbilityType = 'Intellectual Superior';
    }

    const [savedSnackbarOpen, setSavedSnackbarOpen] = useState(false);

    return (
        <>
            <Helmet>
                <title>Report</title>
            </Helmet>
            <AppBar className={classes.appBar} position="static">
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={classes.title}>Report Generation</Typography>
                    <Button variant="contained" color="secondary" startIcon={<SaveIcon />}
                        onClick={async () => {
                            await Firebase.firestore().collection("userProfiles").doc(uid)
                                .collection("2eaaccc9-9e03-462c-824b-9e625bf9a33c")
                                .doc("complete")
                                .set({
                                    generated: true,
                                    reportData: {
                                        reportText: editorState.toString('markdown'),
                                    }
                                }, { merge: true })

                            setSavedSnackbarOpen(true);
                        }}>Save</Button>
                </Toolbar>
            </AppBar>
            <Snackbar open={savedSnackbarOpen} onClose={() => setSavedSnackbarOpen(false)}
                autoHideDuration={6000}>
                <Alert variant="filled" severity="success" action={
                    <Button color="inherit" size="small" onClick={() => history.push("/admin")}>Back to Admin</Button>
                }>
                    Saved
                </Alert>
            </Snackbar>
            <main className={classes.reportMain}>
                <div className={classes.editorColumn}>
                    <Button className={classes.reloadButton} variant="contained" color="secondary" startIcon={<UpdateIcon />}
                        onClick={() => setReportText(editorState.toString('markdown'))}>Reload Report</Button>
                    <RichTextEditor className={classes.editor} editorClassName={classes.editorInternal}
                        placeholder="Use this to fill in the Suggested Careers section of the report."
                        value={editorState} onChange={setEditorState} autoFocus
                        toolbarConfig={{
                            display: [],
                            INLINE_STYLE_BUTTONS: [],
                            BLOCK_TYPE_DROPDOWN: [],
                            BLOCK_TYPE_BUTTONS: []
                        }} />
                </div>
                <div className={classes.gutter} />
                {interestChartImage && intelligenceTable && behaviouralTraitsTable && behaviouralTraitsChartImage && abilityTable && abilityChartImage && actionPlanTable ?
                <PDFViewer className={classes.reportPDF}>
                    <ReportPDF
                        interestChartImage={interestChartImage}
                        intelligenceTable={intelligenceTable}
                        behaviouralTraitsTable={behaviouralTraitsTable}
                        behaviouralTraitsChartImage={behaviouralTraitsChartImage}
                        abilityTable={abilityTable}
                        abilityChartImage={abilityChartImage}
                        actionPlanTable={actionPlanTable}
                        firstName={firstName}
                        reportText={reportText}
                        logoImage={"https://i.imgur.com/8DJiSPT.png"} />
                </PDFViewer> : <div className={classes.reportPDFLoading}><CircularProgress /></div>}
            </main>
            <div className={classes.invisibleLayer}>
                <TableContainer className={classes.intelligenceTable} id="intelligenceTable" component={Paper} variant="outlined" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Grade</TableCell>
                                <TableCell align="center">Percentile</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{iqGrade}</TableCell>
                                <TableCell align="center">{iq}</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} align="center">Ability Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2} align="center">{iqAbilityType}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer className={classes.behaviouralTraitsTable} id="behaviouralTraitsTable" component={Paper} variant="outlined" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Fac</TableCell>
                                <TableCell align="center">Low</TableCell>
                                <TableCell align="center">High</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">A</TableCell>
                                <TableCell align="center">
                                    You have more of a bend in the introversion side, that means you think too much before you speak or take action,  People with this trait are good for jobs which require deep analysis and research
                                </TableCell>
                                <TableCell align="center">
                                    Finding shows that you are an extrovert and outgoing person, this clearly shows your capability in adjusting with people.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">B</TableCell>
                                <TableCell align="center">
                                    It seems that you are not able to use your intellect to your best potential, Your true potential is still to be unearthed.
                                </TableCell>
                                <TableCell align="center">
                                    You are able to use your intellect to its best potential now which is clearly shown by your high scores in Intellect.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">C</TableCell>
                                <TableCell align="center">
                                    Findings show people who are affected by feelings succeed in all forms of careers but only if they try to have a control on it and use it for the right cause. They are great artisans and musicians
                                </TableCell>
                                <TableCell align="center">
                                     Sometimes, you might feel that why do people get emotional for small things, this happens because you don’t get carried away easily with emotions, Which leads you into professions which can be into hardships and working under pressures.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">D</TableCell>
                                <TableCell align="center">
                                    As per your report, It seems that it sometimes takes too much of will to perform small tasks but you do it when you really find it important. People with this attitude succeed due to their power of patience but only if worked with perseverance
                                </TableCell>
                                <TableCell align="center">
                                    You have scores of been Hard charging and take projects to completion, People with such scores generally attract great opportunities but sometimes it is of good to hold on for others
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">E</TableCell>
                                <TableCell align="center">
                                    It seems that you are not generally argumentive and you let  go of things, People like you are adjusting and can work in all environments, Advised to work on environments where team is of utmost importance
                                </TableCell>
                                <TableCell align="center">
                                    Stubbornness can lead you to create history but only if used in the right direction, aggression is also an innate quality for you and leads you to careers where performance means everything.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">F</TableCell>
                                <TableCell align="center">
                                    It shows that generally you are serious about things and you take time and analysis to decide on actions. People with this attitude generally belong to the analysis and research projects.
                                </TableCell>
                                <TableCell align="center">
                                    Findings show that you are an enthusiastic person and take quick decisions on your gut but people sometimes with this trait don’t analyze circumstances.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">G</TableCell>
                                <TableCell align="center">
                                    You make your own rules and people like yours are generally who like to question all aspects of things.
                                </TableCell>
                                <TableCell align="center">
                                    You like following rules and feel bound to it, This will help you to achieve success due to your strategic and well planned approach.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">H</TableCell>
                                <TableCell align="center">
                                    It seems that you might take sometime to open up with people and too extraverts are generally not your buddies, People with such traits are focused and great observers
                                </TableCell>
                                <TableCell align="center">
                                    You have an aggressive and a dominating nature which can help you if used properly.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">I</TableCell>
                                <TableCell align="center">
                                    Your reports defines that you are a person who is tough minded and have a personality which is not too sensitive but people with such traits are not that receptive.
                                </TableCell>
                                <TableCell align="center">
                                    Findings show that you are sensitive and maybe at times overprotected, this sometimes keeps you feeling dependent on others for most of the things
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">J</TableCell>
                                <TableCell align="center">
                                    You like to work in groups and feel more comfortable in it, people with these traits are generally better team players
                                </TableCell>
                                <TableCell align="center">
                                    High scores in extraversion i.e. your happy go lucky behavior can help you  surviving the tough roads in any career but People with this trait sometimes are taken as laidback
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">O</TableCell>
                                <TableCell align="center">
                                    You believe in yourself more than on others, people with this trait generally reject others opinions.
                                </TableCell>
                                <TableCell align="center">
                                    You generally believe and rely on others for opinions, People with this trait are mostly anxious and something bad or unpleasant will happen.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Q2</TableCell>
                                <TableCell align="center">
                                    It shows you are that great team player and will always enjoy company of "similar minds"
                                </TableCell>
                                <TableCell align="center">
                                    You are "the one man army types" and prefer taking your own decisions, People with these traits generally don’t work with teams for too long.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Q3</TableCell>
                                <TableCell align="center">
                                    You like breaking rules and making your own one’s, sometimes that takes you to off beats rather than main stream careers.
                                </TableCell>
                                <TableCell align="center">
                                    You are a person who is precise and self disciplined that will help you plan and strategize things better, People with this trait find it difficult to take raw decisions quickly
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Q4</TableCell>
                                <TableCell align="center">
                                    Findings show that you are a relaxed and a person free from disturbance or calm, Un-frustrated, People with this trait sometimes don’t persevere on things.
                                </TableCell>
                                <TableCell align="center">
                                    Findings show that you might be tensed, this could be because of multiple reasons, Try to avoid it as this might affect your positive approach.
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer className={classes.abilityTable} id="abilityTable" component={Paper} variant="outlined" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Theme</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Verbal Ability</TableCell>
                                <TableCell>VA</TableCell>
                                <TableCell>
                                    Ability to understand as well as express words or ideas and comprehend written language.
                                    This ability is expected to provide success in fields such as
                                    mass media, teaching, writing, creative and technical writing, languages, etc. <br/><br />

                                    <b>
                                        Good Communications<br />
                                        Can Easily Express Ideas<br />
                                        Has a good vocabulary<br />
                                        Likes reading/writing<br />
                                    </b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Numerical Ability</TableCell>
                                <TableCell>NA</TableCell>
                                <TableCell>
                                    It is the ability to the understand numerical relationship and facilitates in handling numerical concepts. The problem are framed in the item type usually called “arithmetic computation” rather than in what is usually called “arithmetic reasoning”. It measures the ability of a person to reason with numbers and to deal intelligently with quantitative measures. People with this abilities are good in understanding, exploring and manipulating systems.<br /><br />

                                    <b>
                                        Scientific & Logical thinking<br />
                                        Can work alone for long hours<br />
                                        Comfortable with numbers<br />
                                        Good quantitative analysis<br />
                                    </b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Spatial Ability</TableCell>
                                <TableCell>SA</TableCell>
                                <TableCell>
                                    Spatial Ability is concerned with perceiving spatial patterns accurately.  This ability has been seen as an indicator of creativity, visualization and non-verbal intelligence. It is a predictor of occupations involving designing, Architecture, commercial art, building construction, die making, decoration, etc.<br /><br />

                                    <b>
                                        3-D imagination and visualization<br />
                                        Culture-fair intelligence<br />
                                        Design and Architecture<br />
                                    </b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Clerical Ability</TableCell>
                                <TableCell>CL</TableCell>
                                <TableCell>
                                    It measures speed of response in a simple perceptual task. It is designed to measure the student’s speed and accuracy.
                                    It measures how accurately and precisely one can do the given task. Can follow instructions quite effectively.
                                    They can attend to a task or work in detail very easily.<br /><br />

                                    <b>
                                        Good Speed and Accuracy<br />
                                        Good Observation Power<br />
                                        Good Concentration<br />
                                        Can do repetitive tasks<br />
                                    </b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Mechanical Ability</TableCell>
                                <TableCell>MA</TableCell>
                                <TableCell>
                                    The mechanical abilities test measures your ability to understand and apply mechanical concepts and principles to solve problems. The person who stands high in these characteristics finds it easy to learn the principles of operation and repair of complex devices.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Closure</TableCell>
                                <TableCell>CA</TableCell>
                                <TableCell>
                                    Closure is the ability to quickly see a whole stimulus when parts of it are missing i.e to fill in gaps in order
                                    to perceive the complete meaningful forms. CA can be expected to be relevant to success in occupations involving
                                    speedy visual perception such as art and designing, computer programming, Architecture, etc.<br /><br />

                                    <b>
                                        Speedy visual perception<br />
                                        Perceive meaningful forms<br />
                                        See whole when parts are missing<br />
                                    </b>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Reasoning Ability</TableCell>
                                <TableCell>RA</TableCell>
                                <TableCell>
                                    Ability to relate specific information to some general rule is known as reasoning. Reasoning is a good indicator of non-verbal,
                                    non-culturally biased intelligence. It is a factor that guarantees success in occupations involving mathematics,
                                    engineering, computer programming, sciences and scientific technology.<br /><br />

                                    <b>
                                        Non-biased intelligence indicator<br />
                                        Scientific and technological pursuits<br />
                                    </b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer className={classes.actionPlanTable} id="actionPlanTable" component={Paper} variant="outlined" square>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="right"></TableCell>
                                <TableCell align="left">My sub-tasks</TableCell>
                                <TableCell align="left">I will tell this to...</TableCell>
                                <TableCell align="left">My target date is...</TableCell>
                                <TableCell align="left">My reward for success will be...</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">1</TableCell>
                                <TableCell align="left">List of matching short term courses, YouTube channels and suggestions</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">2</TableCell>
                                <TableCell align="left">Find ways to develop my weaker skills</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">3</TableCell>
                                <TableCell align="left">Find University offerings, Courses and narrow down your search</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <canvas id="interestChart" style={{display: 'none'}} />
            <canvas id="behaviouralTraitsChart" style={{display: 'none'}} />
            <canvas id="abilityChart" style={{display: 'none'}}/>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        marginBottom: theme.spacing(3),
    },
    title: {
        flexGrow: 1
    },
    reportMain: {
        display: 'flex',
        flexWrap: 'wrap',
        height: '100%'
    },
    editorColumn: {
        flexBasis: '35rem',
        flexGrow: 1,
        margin: theme.spacing(2),
        height: '100%',
    },
    editor: {
        minHeight: '100%',
    },
    editorInternal: {
        overflowX: 'auto'
    },
    reloadButton: {
        minWidth: '100%',
        marginBottom: theme.spacing(1),
    },
    gutter: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '-0.5rem'
    },
    reportPDF: {
        flexBasis: 0,
        flexGrow: 999,
        marginLeft: theme.spacing(2),
        minWidth: `calc(50% - 1rem)`,
        minHeight: '100%'
    },
    reportPDFLoading: {
        flexBasis: 0,
        flexGrow: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    invisibleLayer: {
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none'
    },
    intelligenceTable: {
        width: 300,
    },
    behaviouralTraitsTable: {
        width: 1000,
        '& .MuiTableCell-root': {
            border: '1px solid black'
        }
    },
    abilityTable: {
        width: 1000,
        '& .MuiTableCell-root': {
            border: '1px solid black',
            borderStyle: 'solid none solid none'
        }
    },
    actionPlanTable: {
        width: 1000,
        '& .MuiTableCell-root': {
            border: '1px solid black',
        },
        // My sub-tasks column
        '& .MuiTableCell-root:nth-child(2)': {
            width: '130px'
        },
        // My target date is column
        '& .MuiTableCell-root:nth-child(4)': {
            width: '30px'
        }
    }
}));

const ReportPDF: FC<{interestChartImage: string, intelligenceTable: string, behaviouralTraitsTable: string,
    behaviouralTraitsChartImage: string, abilityTable: string, abilityChartImage: string,
    actionPlanTable: string, firstName: string, reportText: string, logoImage: string}> = (props) => {
    const date = new Date().toLocaleDateString();

    return (
        <Document title={`${props.firstName}'s Career Assessment Report`}>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Image src={props.logoImage} />
                    <Image src={"https://i.imgur.com/OwiyJyR.jpg"} style={{marginBottom: 30}} />
                    <Text style={pdfStyles.title}>{props.firstName}'s Success Story</Text>
                    <Text style={{color: '#C00000', marginLeft: 'auto', marginRight: 0, marginTop: 5, fontSize: '15'}}>{date}</Text>
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        From The Director
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Safal Ho was established many years before it came into real existence, it was
                        sometime in the Year 2006, I started feeling that I am not made to be working in the
                        bank, in spite of a great work environment, good salary, and amazing colleagues who
                        became my lifetime friends. The feeling made me think that if not this, then what is that
                        I wanted to do?
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Couple of days later at a party, I met my mentor and guide, Kunal Jha. He is a senior
                        and a seasoned Sales professional, who introduced me to the “IT World”. Although, he
                        trained me to get into the industry but in the middle, my mind changed and I was
                        pulled into the Media Industry as a “Radio Jockey”, another Industry, new people but
                        again, no internal peace and satisfaction.
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Then again I was back in the same corporate world and decided to get into “IT Sales”
                        with one of the most Fascinating IT company into Hardware sales. Then again the
                        whole thing crashed, and I was filled with a lot of confusions and dilemmas in life, as I
                        had tried Banking, Sales, Call Centres, IT, Telecom and many more folds of the
                        corporate world.
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Well, all this had to go for some more time when I joined another company with a heavy
                        pay package, much more what my peers and same aged people were drawing.

                        This time I sat down and thought, why is this happening to me? But I realized that
                        this was the same feeling of more than 90% of people around me. The internet was filled
                        with researches, Data, and Blogs showing statistics, that people choose careers and
                        later realize that they don’t belong there and don’t see a connection.
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        That’s when I started to work and discovered that there are certain ways to unearth
                        this hidden secret, which lies in scientific tests, which are authentic and scientifically
                        proven.
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        This is where Safal Ho started and we strive to help Career aspirants to learn their own
                        abilities, Interests, and competencies.

                        In this journey I would like to thank, My parents, My brother Himanshu
                        Rohit, Kunal, Gourav, Anupam, Rohit, Akash, and everyone else who I might have
                        missed on adding.

                        Without all of you this would have never been possible.
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        ---
                        Shivanshu
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Safal Ho
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        “May Kugoo Bless You”
                    </Text>
                </View>
            </Page>
            {/* Description of tests */}
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        SAFAL HO Tests and Description
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        SAFAL HO Test of Intelligence 
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        It is a measure of Intelligence of an individual.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        SAFAL HO Test of Interest 
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        It indicates the most preferred and least preferred interest areas of an individual and it has 10 Parameters.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        SAFAL HO Test of Behavioral Traits
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        It is a measure of the behavioral traits of an individual.
                        The combination of characteristics or qualities that form an individual's distinctive character.
                        The test is comprehensive which gives 14 behavioral traits.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        SAFAL HO Test of Abilities
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        It measures what scientific tests result refer to as your fluid
                        and crystallized intelligence. The theory of fluid and crystallized intelligence suggests that people’s intelligence is composed of a number of different
                        abilities that interact and work together to produce overall intelligence of an individual. Generally, there are 7 Parameters.   
                    </Text>
                </View>
            </Page>
            {/* Intelligence test results */}
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        {`${props.firstName}'s Mental Ability`}
                    </Text>
                    <Image src={props.intelligenceTable} style={pdfStyles.intelligenceTable} />
                    <Text>
                        Intelligence quotient means:
                    </Text>
                    <List>
                        <Item>
                            It is the ability of abstract thinking, general adaptability and problem solving.
                        </Item>
                        <Item>
                            It is the capability to adjust to new situations.
                        </Item>
                        <Item>
                            It is the ability to relate to diverse situations.
                        </Item>
                        <Item>
                            It is the tendency to act or think in a particular way & reflects flexibility of mind.
                        </Item>
                        <Item>
                            It is concentration of energy & global capacity.
                        </Item>
                        <Item>
                            It is the ability to learn from experiences.
                        </Item>
                        <Item>
                            It is the ability to carry on higher process of thoughts.
                        </Item>
                        <Item>
                            It is the power of the mind and its capabilities.
                        </Item>
                        <Item>
                            It is the capability for clear thinking and accurate intelligence work.
                        </Item>
                    </List>
                    <Text>
                        Later in this report we will see how you utilize your intelligence.
                    </Text>
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        {`${props.firstName}'s top interests and findings`}
                    </Text>
                    <Text style={[pdfStyles.sectionDescription, pdfStyles.firstMargin]}>
                        The test of Interest assess the various “interests” of an individual and classify them into high, medium or low scale.
                        It is always better to choose a career where one’s interest is high because, only then the person will find the career interesting.
                        Their productivity and personal satisfaction will be high in such careers.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Fine Arts 
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Artistic - Fine arts area of interest is represented by the subjects or activities likes sculptures, music, songs, toy making, wood craft, art drawing, and painting, art of decoration, dance, photography, cartoon making etc.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Literature
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        The literary scale includes the area of editing, translating, journalism, poetry, reading, writing, language specialization, drama writing, epic writer, language teaching, novel & story writing etc.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Science
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Science area includes the subjects like zoology, botany, geology, meteorology, science of atoms, mathematics, surgery, science of health, physiology, general science etc.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Medical 
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Medical field includes all area of mammals & animal studying.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Agriculture
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        The agriculture interest area includes the activities and subjects like animal husbandry, farming, study of manures, fruit preservation, dairying, agriculture extension, reforms in villages, veterinary science, agriculture, botany etc. 
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Technical
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        This field of interest is represented by the subject/activities like fitters, electrical, mechanical
                        & civil engineering, welding, electrical drawing, radio-TV engineering, applied mathematics,
                        Indian technology, general technology, science of metals etc.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Craft
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Primarily, it is the interest in hand work and skills.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Outdoors
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Leads to an active life and a naturalist. Anything to do where their might be travelling and meeting new people is involved.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        Sports
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Interest in sports and in leading an active life.
                    </Text>
                    <Text style={pdfStyles.sectionTitle}>
                        House hold work
                    </Text>
                    <Text style={pdfStyles.sectionDescription}>
                        Household area is covered through the subject of general home science, preparation of home budget, hygiene, cooking, home management, home decoration, embroidery, child care, and music, dance etc.
                    </Text>
                    <Image style={pdfStyles.interestChart} src={props.interestChartImage} />
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        Description of Behavioural Traits
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.sectionDescription}>
                        This test measures the enduring characteristics of the person that are significant for interpersonal behavior. Behavioral traits is the uniqueness of a person. It is the collection of emotions, thought and behavioral patterns that is consistent over a period of time. In personality it has a great impact on the person’s performance. Hence, it is necessary to know one’s own behavioral traits in selecting careers.
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.title}>
                        Meaning of Behavioural Traits
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.sectionDescription}>
                        We all have distinguished behavioral traits. It is the set of emotional qualities and types of behavior that makes a person different from other people and unique in their own manner. Like a few of us are more analytical in nature, while others may have an artistic mind. One may be good at managing numbers; others may excel at handling people. 

                        These inbuilt skills will help to shape the career smoothly and the chosen job will be more satisfying, which helps to build a successful career. 

                        "Behavioral traits is a dynamic organization within the individual; it is a psychological system that determines their unique adjustment to their environment".
                    </Text>
                    <Text>
                        See the next page for a description of the individual traits in the above chart.
                    </Text>
                </View>
            </Page>
            <Page size="A4" orientation="landscape" wrap={false}>
                <View>
                    <Image style={pdfStyles.behaviouralTraitsChart} src={props.behaviouralTraitsChartImage} />
                </View>
            </Page>
            <Page size="A4">
                <View>
                    <Image style={pdfStyles.behaviouralTraitsTable} src={props.behaviouralTraitsTable} />
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        Description of Abilities & Different Measures
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.sectionDescription}>
                        It may be true that with hard work and determination, one can perform well in any field. However, having an ability for that field can make things much easier. But what is abilities? In the simplest of terms, ability is nothing but the natural ease of learning something and if that is there, a career journey can become very easy and rewarding. Hence, it becomes essential that you first know your ability and then choose a career. 
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.sectionDescription}>
                        For example, you may be very good at communication, so that is your area of High abilities. This means your communication skills do not require much training. While you are very good at communication, you may be average at writing (your area of medium abilities). Your body language may not be up to the mark, this is your area of Low abilities and needs the greatest amount of help. Please find your associated scores for different abilities parameters.
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.sectionDescription}>
                        An abilities is a combination of characteristics indicative of an individual’s capacity to acquire some specific skills or set of organized responses such as ability to speak a language, to become a musician or to do mechanical work.
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.sectionDescription}>
                        It is an abstract phenomenon and an integrated part of a person’s behavioral traits. Abilities can be predictive about the individual’s future, performance or occupation. Abilities brings excellence in top performance. Abilities of an individual are fairly stable but not perfectly constant. Each individual has different potentials. 
                    </Text>
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        Measures of Abilities
                    </Text>
                    <Image style={pdfStyles.firstMargin} src={props.abilityTable} />
                </View>
            </Page>
            <Page size="A4" orientation="landscape">
                <View style={pdfStyles.section}>
                    <Image style={pdfStyles.abilityChart} src={props.abilityChartImage} />
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        Suggested Careers
                    </Text>
                    <Text>{props.reportText}</Text>
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Text style={pdfStyles.title}>
                        {"Making it all happen:\n Your action plan and goals"}
                    </Text>
                    <Text style={[pdfStyles.firstMargin, pdfStyles.sectionDescription]}>
                        Create immediate short-term actions and draft a longer-term plan to maximize your career potential and learning opportunities.

                        Research suggests that the best way to achieve goals is to state your goal clearly, detail sub-tasks, set target dates,
                        tell other people and reward yourself for completing tasks. You should also detail the positive benefits of completing your
                        goals as well as your level of commitment to them. Use this section of the report to outline your priorities.
                    </Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.actionPlanTitle}>My priority goal is:</Text>
                    <Text>{"\n"}</Text>
                    <Text style={pdfStyles.actionPlanTitle}>Commitment to my goal on a scale of 1-10 where 1 is minimal and 10 is total:</Text>
                    <Image style={pdfStyles.actionPlanSubsteps} src={props.actionPlanTable} />
                    <Text style={pdfStyles.actionPlanTitle}>How I will benefit from achieving my goal: (for example I will be in a job I enjoy, living out my values; being recognized as an expert)</Text>
                    <List>
                        <Item />
                        <Item />
                    </List>
                    <Text style={pdfStyles.actionPlanTitle}>Mail your queries to research@safalho.com</Text>
                </View>
            </Page>
        </Document>
    );
}

const pdfStyles = StyleSheet.create({
    section: {
        margin: 30
    },
    rowSection: {
        flexDirection: "row"
    },
    title: {
        textAlign: 'center',
        color: '#C00000',
        fontSize: "30",
        fontFamily: 'Helvetica-Bold',
        borderStyle: 'dashed',
        borderWidth: 2,
    },
    sectionTitle: {
        fontFamily: 'Helvetica-Bold',
        marginTop: 30
    },
    sectionDescription: {
        fontFamily: 'Helvetica',
        textIndent: 30
    },
    firstMargin: {
        marginTop: 30
    },
    intelligenceTable: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
        marginBottom: 20,
        width: 300
    },
    interestChart: {
        marginTop: 50
    },
    behaviouralTraitsTable: {
        width: '100%',
        height: '100%',
    },
    behaviouralTraitsChart: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 20,
        marginRight: 20,
        objectFit: 'contain'
    },
    abilityChart: {
        position: 'relative',
        top: '20%'
    },
    actionPlanTitle: {
        display: 'table',
        backgroundColor: 'red',
        padding: 5,
        color: '#ffffff'
    },
    actionPlanSubsteps: {
        marginTop: 10,
    }
});

const itemStyles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  bulletPoint: {
    width: 10,
  },
  itemContent: {
    flex: 1,
  },
});

const List: FC = ({ children }) => <>{children}</>;

const Item: FC = ({ children }) => (
  <View style={itemStyles.item}>
    <Text style={itemStyles.bulletPoint}>•</Text>
    <Text style={itemStyles.itemContent}>{children}</Text>
  </View>
);

export default Report;