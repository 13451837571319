import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { from, of, merge } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Epic, combineEpics } from 'redux-observable';
import { QuestionsSliceState, newQuestions } from '../engine/slice';
import { MiddlewareDependencies, RootState } from '../../store';
import { pickBy } from 'ramda';
import { actionTypes as firebaseActionTypes } from 'react-redux-firebase';

export type PaginationSliceState = {
    currentAssessment: string | undefined,
    currentSection: string | undefined,
    currentPage: number,
    totalPages: number | undefined,
}

export const loadTotalPages = createAsyncThunk<
    number,
    void,
    { extra: MiddlewareDependencies, state: RootState }
>(
    'pagination/loadTotalPages',
    async (_, { extra: { Firebase }, getState, rejectWithValue }) => {
        const { currentAssessment, currentSection } = getState().pagination;

        if(currentAssessment === undefined || currentSection === undefined) {
            return rejectWithValue(undefined);
        };

        const totalDoc = Firebase.firestore().doc(`assessments/${currentAssessment}/${currentSection}/total`);

        try {
            return (await totalDoc.get({ source: "cache" })).get('total');
        } catch {
            return (await totalDoc.get()).get('total');
        }
    }
);

export const submitSection = createAsyncThunk<
    void,
    void,
    { extra: MiddlewareDependencies, state: RootState }
>(
    'pagination/submitSection',
    async (_, { extra: { Firebase }, getState, rejectWithValue }) => {
        const submitSectionServer = Firebase.functions().httpsCallable('submitSection');

        const { currentAssessment, currentSection } = getState().pagination;

        if (currentAssessment === undefined || currentSection === undefined) {
            return rejectWithValue(undefined);
        }

        await submitSectionServer({ assessment: currentAssessment, section: currentSection });
    }
)

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState: {
        currentAssessment: undefined,
        currentSection: undefined,
        currentPage: 1,
        totalPages: undefined,
    } as PaginationSliceState,
    reducers: {
        changePage(state, action: PayloadAction<{ currentPage: number, newPage: number }>) {
            const { newPage } = action.payload;

            if (newPage <= 0) return;

            state.currentPage = newPage;
        },
        setAssessment(state, action: PayloadAction<string>) {
            state.currentAssessment = action.payload;
        },
        setSection(state, action: PayloadAction<string>) {
            state.currentSection = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadTotalPages.fulfilled, (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        });
    }
});

export const { changePage, setAssessment, setSection } = paginationSlice.actions;

const loadPageEpic: Epic = (action$, state$, { Firebase }: MiddlewareDependencies) => {
    async function saveResults(section: string, results: QuestionsSliceState["results"]) {
        const userId = Firebase.auth().currentUser?.uid;
        const resultsDoc = Firebase.firestore().doc(`userProfiles/${userId}`);

        await resultsDoc.set({ results: { [section]: pickBy(Boolean, results) } }, { merge: true });
    }

    async function loadPage(newPage: number, assessment: string, section: string) {
        const page = Firebase.firestore().doc(`assessments/${assessment}/${section}/${newPage}`);

        try {
            return await page.get({ source: "cache" });
        } catch {
            return await page.get();
        }
    }

    return action$.pipe(
        // This isn't the cleanest way to make sure certain state is defined
        // when changePage is called, but it prevents weird race conditions
        // from the dispatch order, and I don't know enough RxJS to find
        // any other reasonable way.
        filter(action => changePage.match(action) || setSection.match(action) || action.type === firebaseActionTypes.SET_PROFILE),
        withLatestFrom(state$),
        filter(([_, state]) => state.pagination.currentPage !== undefined && 
            state.firebase.profile.isLoaded &&
            state.pagination.currentAssessment !== undefined && state.pagination.currentSection !== undefined),
        switchMap(([_, state]) => {
            const { currentAssessment, currentSection, currentPage: newPage } = state.pagination;

            return merge(
                of(newQuestions({ questions: [] })),
                of(loadTotalPages()),
                from(Promise.all<any, any>([
                    loadPage(newPage, currentAssessment, currentSection),
                    state.questions.isDirty ? saveResults(currentSection, state.questions.results) : Promise.resolve({}),
                ])).pipe(
                    map(([page, _]) => page.get('questions')),
                    map(questions => newQuestions({
                        questions: questions,
                        results: state.firebase.profile.results?.[currentSection],
                    }))
                ),
            );
        })
    );
}

export const paginationEpic = combineEpics(loadPageEpic);

export default paginationSlice.reducer;
