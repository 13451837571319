import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, CircularProgress } from '@material-ui/core';
import Firebase from "firebase/app";
import * as FirebaseUI from 'firebaseui';
import { FirebaseAuth } from 'react-firebaseui';
import { useHistory } from 'react-router-dom';

const Login: FC = () => {
    const classes = useStyles();

    // @ts-ignore
    const currentUser = useSelector(state => state.firebase.auth);

    const history = useHistory();

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Container className={classes.container}>
                {currentUser.isLoaded ? (
                    <>
                        <Typography variant="h4">
                            {!currentUser?.isAnonymous ?
                                <>To start the test, please sign up or sign in:</>
                                : <>Convert your guest account to a permanent account:</>}
                        </Typography>
                        <FirebaseAuth
                            firebaseAuth={Firebase.auth()}
                            uiConfig={{
                                signInOptions: [
                                    {
                                        provider: Firebase.auth.EmailAuthProvider.PROVIDER_ID,
                                        clientId: '633067227301-a07lcv312taglbm1ihn36r4gq4t7ajg0.apps.googleusercontent.com'
                                    },
                                    Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                                    Firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                                ],
                                credentialHelper: FirebaseUI.auth.CredentialHelper.GOOGLE_YOLO,
                                callbacks: {
                                    signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential) => {
                                        if (authResult.user) {
                                            authResult.user.getIdTokenResult()
                                                .then(token => {
                                                    console.log(token);

                                                    if (!token.claims.isAdmin) {
                                                        history.push("/assessment-listing");
                                                    } else {
                                                        history.push("/admin");
                                                    }
                                                });
                                        }

                                        return false;
                                    }
                                },
                                autoUpgradeAnonymousUsers: true
                            }}
                        />
                    </>
                ) : <CircularProgress />}
            </Container>
        </>
    );
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default Login;
