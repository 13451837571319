export enum ContentType {
    Title = 'title',
    Text = 'text',
    Math = 'math',
    Image = 'image'
};

export type Content = {
    type: ContentType,
    body: string
};

export enum QuestionType {
    Single = 'single',
    Composite = 'composite'
}

export enum RenderingHint {
    Row = 'row',
    Column = 'column',
    Grid = 'grid',
    Categorization = 'categorization',
    Ordering = 'ordering'
};

export type AnswerData = {
    id: string,
    content: Content[]
}

export type QuestionData = {
    id?: string,
    sequenceNumber: number,
    type: QuestionType,
    renderingHint: RenderingHint[],
    content: Content[],
    answerInfo?: { allowedSelectionCount: number },
    answers?: AnswerData[],
    subquestions?: QuestionData[]
};

export type Result = AnswerData['id'] | AnswerData['id'][]
                     | Record<any, AnswerData['id']> | Record<any, AnswerData['id'][]>
