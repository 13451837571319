import React, { FC, useState, useEffect, useCallback } from "react";
import { Link, useHistory } from 'react-router-dom';
import { AppBar, CircularProgress, Toolbar, Fab, TextField, DialogActions, Container, IconButton, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Typography, Card, CardContent, CardActions, Button, LinearProgress } from "@material-ui/core";
import { Dialog, DialogContent, DialogContentText, DialogTitle, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import Firebase from 'firebase/app';
import 'firebase/functions';
import { LockOpen as LockOpenIcon, VpnKey as VpnKeyIcon, ExitToApp as ExitToAppIcon, AccountCircle as AccountCircleIcon } from '@material-ui/icons'
import Logo from '../Logo';
import Joyride, { LIFECYCLE } from 'react-joyride';
import Cookies from 'js-cookie';

const AssessmentListing: FC = () => {
    const classes = useStyles();

    const [assessments, setAssessments] = useState([] as {id: string, name: string, enabled: boolean}[]);

    const [unlockDialogOpen, setUnlockDialogOpen] = useState(false);
    const [activationCode, setActivationCode] = useState("");
    const [unlockErrorText, setUnlockErrorText] = useState("");
    const [processingActivationCode, setProcessingActivationCode] = useState(false);

    const processActivationCode = Firebase.functions().httpsCallable('processActivationCode');

    useEffect(() => {
        return Firebase.auth().onIdTokenChanged(async user => {
            if (user) {
                const userEnabledAssessments =
                    (await user.getIdTokenResult(true)).claims.enabledAssessments || [];

                const allAssessmentsCollection = Firebase.firestore().collection("assessments");

                const allAssessmentsSnapshot = await allAssessmentsCollection.get();

                setAssessments(
                    allAssessmentsSnapshot.docs.map(doc => ({
                        id: doc.id,
                        name: doc.get('name'),
                        enabled: (
                            userEnabledAssessments.includes(doc.id) ||
                            (!userEnabledAssessments?.length && doc.get('defaultEnabled'))
                        ),
                    }))
                );
            }
        });
    }, []);

    const history = useHistory();

    const [name, setName] = useState<string | undefined>();
    const [profileClass, setProfileClass] = useState<string | undefined>();
    const [gender, setGender] = useState<string | undefined>();
    const [age, setAge] = useState<string | undefined>();

    const [profileDialogMessage, setProfileDialogMessage] = useState<string>("");
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);

    const handleProfileDialogClose = useCallback(() => {
        if (!(name && profileClass && gender && age)) {
            setProfileDialogMessage("Please fill out everything in the profile.");
            return;
        }

        setProfileDialogMessage("Loading...");

        Firebase.firestore().collection("userProfiles")
            .doc(Firebase.auth().currentUser!.uid)
            .set({
                categories: {
                    "class": profileClass || null,
                    gender: gender || null,
                    age: age || null,
                },
                name: name || null,
            }, { merge: true })
            .then(() => {
                setProfileDialogMessage("");
                setProfileDialogOpen(false)
            });
    }, [name, profileClass, gender, age]);

    useEffect(() => {
        async function checkUserProfile() {
            const user = await Firebase.firestore().collection("userProfiles")
                .doc(Firebase.auth().currentUser!.uid)
                .get();

            const userProfile = user.data();

            setName(userProfile?.name);
            setAge(userProfile?.categories.age);
            setGender(userProfile?.categories.gender);
            setProfileClass(userProfile?.categories.class);

            if (!(userProfile && userProfile.categories.age && userProfile.categories.class
                    && userProfile.categories.gender && userProfile.name)) {
                setProfileDialogOpen(true);
            }
        }

        checkUserProfile();
    }, [])

    return (
        <>
            <Helmet>
                <title>Assessment Listing</title>
            </Helmet>
            <header>
                <AppBar position="static">
                    <Toolbar>
                        <Logo />
                        <Typography variant="h6" className={classes.title}>Available Assessments</Typography>
                        <IconButton color="inherit" onClick={() => setProfileDialogOpen(true)}>
                            <AccountCircleIcon fontSize="large" />
                        </IconButton>
                        <Button color="secondary"
                            startIcon={<ExitToAppIcon />}
                            variant="contained" onClick={() => {
                                Firebase.auth().signOut().then(() => history.push("/"));
                            }}>
                                Sign Out
                        </Button>
                    </Toolbar>
                </AppBar>
            </header>
            <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose}>
                <DialogTitle>User Profile</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your profile information so we can score your assessments correctly.
                    </DialogContentText>
                    <form className={classes.profileFormContainer}>
                        <TextField className={classes.profileFormControl} required label="Name" variant="outlined"
                            value={name} onChange={event => setName(event.target.value as string)} />
                        <FormControl className={classes.profileFormControl} required variant="outlined">
                            <InputLabel>Class</InputLabel>
                            <Select value={profileClass} onChange={event => setProfileClass(event.target.value as string)} input={<Input />}>
                                <MenuItem value="9">9th Class</MenuItem>
                                <MenuItem value="10">10th Class</MenuItem>
                                <MenuItem value="11">11th Class</MenuItem>
                                <MenuItem value="12">12th Class</MenuItem>
                                <MenuItem value="C">College Student</MenuItem>
                                <MenuItem value="A">Adult</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.profileFormControl} required variant="outlined">
                            <InputLabel>Gender</InputLabel>
                            <Select value={gender} onChange={event => setGender(event.target.value as string)} input={<Input />}>
                                <MenuItem value="m">Male</MenuItem>
                                <MenuItem value="f">Female</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.profileFormControl} required variant="outlined">
                            <InputLabel>Age</InputLabel>
                            <Select value={age} onChange={event => setAge(event.target.value as string)} input={<Input />}>
                                <MenuItem value="14">14</MenuItem>
                                <MenuItem value="15">15</MenuItem>
                                <MenuItem value="16">16</MenuItem>
                                <MenuItem value="17">17</MenuItem>
                                <MenuItem value="18">18</MenuItem>
                                <MenuItem value="19">19</MenuItem>
                                <MenuItem value="20">20</MenuItem>
                                <MenuItem value="21">21</MenuItem>
                                <MenuItem value="22">22</MenuItem>
                                <MenuItem value="23+">23+</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                    <DialogContentText>
                        {profileDialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProfileDialogClose} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <main className={classes.listing}>
                {assessments?.length > 0 ? (<>
                    {assessments.map(assessmentObj => (
                        <Card key={assessmentObj.id} className={classes.assessmentCard} elevation={assessmentObj.enabled ? 3 : 1}>
                            <CardContent>
                                <Typography variant="h5" color={assessmentObj.enabled ? "textPrimary": "textSecondary"}>
                                    {assessmentObj.name}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant="outlined" color="secondary"
                                    component={Link} to={`/assessment/${assessmentObj.id}`}
                                    size="medium" disabled={!assessmentObj.enabled}>
                                    Start
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
                </>): <Container className={classes.loadingContainer}><CircularProgress /></Container>}
            </main>
            <Fab color="secondary" variant="extended" className={classes.unlockButton} onClick={() => setUnlockDialogOpen(true)}>
                <LockOpenIcon className={classes.unlockButtonIcon} />
                Unlock Assessments
            </Fab>
            <Joyride continuous showSkipButton run={!Cookies.getJSON('assessmentListingJoyrideDone')} steps={[
                {
                    target: `.${classes.listing}`,
                    content: "You can see all your assessments here.",
                    disableBeacon: true,
                },
                {
                    target: `.${classes.unlockButton}`,
                    content: "Use your assessment code to unlock new assessments here."
                }
            ]} locale={{ last: 'Finish' }} callback={({ lifecycle }) => {
                if(lifecycle === LIFECYCLE.COMPLETE) {
                    Cookies.set('assessmentListingJoyrideDone', 'true', { path: '' });
                }
            }}/>
            <Dialog open={unlockDialogOpen} onClose={() => setUnlockDialogOpen(false)}>
                <DialogTitle>Unlock new assessments</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter your 10-character long activation code:
                    </DialogContentText>
                    <TextField
                      variant="outlined"
                      autoFocus margin="dense" fullWidth
                      id="activation-code" label="Code"
                      value={activationCode}
                      onChange={e => {
                          const userCode = e.target.value;

                          if (userCode.length <= 10) {
                              setUnlockErrorText("");
                              setActivationCode(userCode);
                          }
                      }}
                      error={Boolean(unlockErrorText)}
                      helperText={!unlockErrorText ? `${activationCode.length}/10` : unlockErrorText}
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                  <VpnKeyIcon />
                              </InputAdornment>
                          )
                      }}
                      />
                    {processingActivationCode ? <LinearProgress color="secondary" /> : null}
                    <DialogActions>
                        <Button onClick={() => setUnlockDialogOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={async () => {
                            try {
                                setProcessingActivationCode(true);
                                await processActivationCode({ activationCode });
                            } catch(error) {
                                setUnlockErrorText(error.message);
                                setProcessingActivationCode(false);
                                return;
                            }

                            // Force the ID token to refresh so that custom claims are updated
                            await Firebase.auth().currentUser?.getIdToken(true);
                            setAssessments([]);
                            setProcessingActivationCode(false);
                            setUnlockDialogOpen(false);
                        }} disabled={activationCode.length !== 10 || processingActivationCode} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    listing: {
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1
    },
    unlockButton: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    unlockButtonIcon: {
        marginRight: theme.spacing(1),
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    assessmentCard: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        display: 'inline-block',
        minWidth: '20%'
    },
    profileFormContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    profileFormControl: {
        margin: theme.spacing(1),
        minWidth: 100
    }
}));

export default AssessmentListing;