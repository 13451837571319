import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';
import { Content, ContentType } from './types';

type ContentRendererProps = {
    content: Content,
    [restProp: string]: any,
}

const ContentRenderer: FC<ContentRendererProps> = ({ content, ...restProps }) => {
    const classes = useStyles();

    switch (content.type) {
        case ContentType.Title:
            return <Typography variant="h4" className={classes.title} {...restProps}>{content.body}</Typography>;
        case ContentType.Text:
            return <Typography variant="body1" {...restProps}>{content.body}</Typography>;
        case ContentType.Math:
            return <TeX math={content.body} className={classes.math} {...restProps} />;
        case ContentType.Image:
            return <img loading="lazy" src={content.body} alt="" className={classes.image} {...restProps}/>;
    }
};

const useStyles = makeStyles(theme => ({
    title: {
        whiteSpace: 'pre-wrap'
    },
    math: {
        fontSize: '1.6em',
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
    }
}));

export default ContentRenderer;
