import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Logo: FC = () => {
    const classes = useStyles();

    return <img className={classes.logo} height="70" width="150" src="http://safalho.com/wp-content/uploads/2018/04/0.png" alt="Logo" />;
}

const useStyles = makeStyles(theme => ({
    logo: {
        padding: theme.spacing(1),
        marginRight: theme.spacing(2),
    }
}));

export default Logo;