import { configureStore } from '@reduxjs/toolkit';
import Firebase from 'firebase/app';
import { firebaseReducer, actionTypes as firebaseActionTypes } from 'react-redux-firebase';
import { Epic, createEpicMiddleware, combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import questionsReducer from './features/engine/slice';
import paginationReducer, { paginationEpic } from './features/pagination/slice';

export type MiddlewareDependencies = {
    Firebase: Firebase.app.App
}

const epicMiddleware = createEpicMiddleware({
    dependencies: { Firebase: Firebase }
});

const store = configureStore({
    reducer: {
        firebase: firebaseReducer,
        questions: questionsReducer,
        pagination: paginationReducer
    },
    middleware: getDefaultMiddleware =>
        [...getDefaultMiddleware({
             thunk: { extraArgument: { Firebase: Firebase }},
             serializableCheck: {
                 ignoredActions: [firebaseActionTypes.LOGIN],
             }
        }), epicMiddleware]
});

const rootEpic: Epic = (action$, store$, dependencies) =>
    combineEpics(paginationEpic)(action$, store$, dependencies).pipe(
       catchError((error, source) => {
           console.error(error);
           return source;
       })
    );

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
export default store;
