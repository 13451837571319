import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, Tooltip, CircularProgress, Snackbar } from '@material-ui/core';
import { Slide, AppBar, Toolbar, Paper, Dialog, DialogActions, DialogContent, useScrollTrigger } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Help as HelpIcon, ArrowBack as ArrowBackIcon, Publish as PublishIcon, Close as CloseIcon } from '@material-ui/icons';
import { Prompt, useParams, Link, useHistory } from 'react-router-dom';
import { setAssessment, setSection, changePage, submitSection } from '../features/pagination/slice';
import useBeforeUnload from 'use-before-unload';
import QuestionSet from '../features/engine/questionSet';
import Logo from '../Logo';
import PaginationBar from '../features/pagination/paginationBar';
import { currentPageSelector } from '../features/pagination/selectors';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import TeX from '@matejmazur/react-katex';
import 'katex/dist/katex.min.css';
import math from 'remark-math';
import gfm from 'remark-gfm';
import Firebase from 'firebase/app';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import clsx from 'clsx';
import { Alert, AlertProps } from '@material-ui/lab';

const Assessment: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { assessmentId, sectionId, sectionName } = useParams<{ assessmentId: string, sectionId: string, sectionName: string }>();

    useEffect(() => {
        dispatch(setAssessment(assessmentId));
        dispatch(setSection(sectionId));
        dispatch(changePage({ currentPage: 1, newPage: 1 }));
    }, [dispatch, assessmentId, sectionId]);

    const currentPage = useSelector(currentPageSelector);

    const [submitLoading, setSubmitLoading] = useState(false);
    const history = useHistory();

    const userLeaveConfirmationMessage = "Are you sure you want to leave this section?";
    useBeforeUnload(_ => {
        if(process.env.NODE_ENV && process.env.NODE_ENV === 'production' && !submitLoading) {
            return userLeaveConfirmationMessage;
        }
    });

    const scrollTrigger = useScrollTrigger();

    const [sectionInfoDialogOpen, setSectionInfoDialogOpen] = useState(true);

    const handleDialogClose = useCallback(() => {
        setSectionInfoDialogOpen(false);
        setTimerPlaying(true);
    }, []);

    const [sectionInfo, setSectionInfo] = useState<string | undefined>(undefined);

    const [timerPlaying, setTimerPlaying] = useState(false);

    const theme = useTheme();

    useEffect(() => {
        async function loadSectionInfo() {
            const doc = Firebase.firestore().doc(`assessments/${assessmentId}/${sectionId}/info`);

            try { 
                const info = await doc.get({ source: "cache" });
                setSectionInfo(info.get('info'));
                setTimeLimit(info.get('timeLimit'));
                setTimerEnforced(info.get('timerEnforced'));
            } catch {
                const info = await doc.get();
                setSectionInfo(info.get('info'));
                setTimeLimit(info.get('timeLimit'));
                setTimerEnforced(info.get('timerEnforced'));
            }
        }

        loadSectionInfo();
    }, [assessmentId, sectionId]);

    const [timerEnforced, setTimerEnforced] = useState(true);
    const [timeLimit, setTimeLimit] = useState<number | undefined>(undefined);
    const [timeUp, setTimeUp] = useState(false);
    const [timerSnackbar, setTimerSnackbar] = useState<{
        isOpen: boolean,
        message: string,
        severity: AlertProps['severity'],
      }>({ isOpen: false, message: "", severity: undefined });

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Assessment</title>
            </Helmet>
            {process.env.NODE_ENV && process.env.NODE_ENV === 'production' ?
                <Prompt message={userLeaveConfirmationMessage} /> : null}
            <header>
                <Slide appear={false} direction="down" in={!scrollTrigger}>
                    <AppBar className={classes.topAppBar}>
                        <Toolbar>
                            <Tooltip title="Back">
                                <IconButton edge="start" className={classes.backButton}
                                     color="inherit" component={Link} to={`/assessment/${assessmentId}`}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                            <Logo />
                            <Typography variant="h6" className={classes.title}>{sectionName}</Typography>
                            <Button color="secondary"
                             variant="contained" className={classes.submitButton} startIcon={<PublishIcon />} onClick={() => {
                                 setSubmitLoading(true);
                                 dispatch(changePage({ currentPage, newPage: currentPage }));
                                 // @ts-ignore
                                 dispatch(submitSection()).then(() => history.push(`/assessment/${assessmentId}`));
                             }}>
                                {!submitLoading ? "Submit Section" : <CircularProgress />}
                            </Button>
                            <Tooltip title="Help">
                                <IconButton color="inherit" onClick={() => setSectionInfoDialogOpen(true)}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                </Slide>
            </header>
            {timeLimit ? <div className={clsx(classes.timer, {[classes.timerAppBar]: !scrollTrigger})}>
                <CountdownCircleTimer duration={timeLimit}
                    size={100}
                    colors={[[theme.palette.success.main, 0.3],
                            [theme.palette.warning.main, 0.3],
                            [theme.palette.error.main, 0.3]]} isPlaying={timerPlaying}>
                    {({ remainingTime }) => {
                        if (!remainingTime) {
                            if (!timerSnackbar.isOpen) {
                                setTimerSnackbar({
                                    isOpen: true,
                                    message: timerEnforced ? "Time's up! Submit your answers now." : "Time's up! Finish and submit as soon as possible.",
                                    severity: "error"
                                });

                                setTimeUp(true);
                            }
                             return "Time's up!";
                        }

                        const hours = Math.floor(remainingTime / 3600).toLocaleString();
                        const minutes = Math.floor((remainingTime % 3600) / 60).toLocaleString().padStart(2, '0');
                        const seconds = (remainingTime % 60).toLocaleString().padStart(2, '0');

                        if (remainingTime === timeLimit - (timeLimit / 2)) {
                            if (!timerSnackbar.isOpen) {
                                setTimerSnackbar({
                                    isOpen: true,
                                    message: "You've used up half your time!",
                                    severity: "warning"
                                });
                            }
                        }

                        if (remainingTime === 300 && timeLimit >= 700) {
                            if (!timerSnackbar.isOpen) {
                                setTimerSnackbar({
                                    isOpen: true,
                                    message: "Only FIVE minutes left!",
                                    severity: "error"
                                });
                            }
                        }

                        if (remainingTime === 120) {
                            if (!timerSnackbar.isOpen) {
                                setTimerSnackbar({
                                    isOpen: true,
                                    message: "Only TWO minutes left!",
                                    severity: "error"
                                });
                            }
                        }

                        if (timeLimit >= 3600) {
                            return <b>{hours}:{minutes}:{seconds}</b>;
                        } else {
                            return <b>{minutes}:{seconds}</b>;
                        }
                    }}
                </CountdownCircleTimer>
            </div> : null}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={timerSnackbar.isOpen}
                onClose={() => setTimerSnackbar(timerSnackbar => ({ ...timerSnackbar, isOpen: false }))}
                autoHideDuration={10000}>
                <Alert variant="filled" severity={timerSnackbar.severity}>
                    {timerSnackbar.message}
                </Alert>
            </Snackbar>
            <main className={classes.questions}>
                <QuestionSet disabled={submitLoading || (timeUp && timerEnforced)} />
                 {/* Temporary padding to avoid the pagination bar covering up the content */}
                <Paper className={classes.extra}></Paper>
            </main>
            <Dialog fullScreen open={sectionInfoDialogOpen} onClose={handleDialogClose} TransitionComponent={Slide} className={classes.dialog}>
                <AppBar className={classes.dialogAppBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleDialogClose}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.dialogTitle}>
                            {sectionName} Section Info
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.dialogContent}>
                    {sectionInfo ? <ReactMarkdownWithHtml
                        // @ts-ignore
                        plugins={[gfm, math]}
                        renderers={{
                            inlineMath: ({value}) => <TeX className={classes.math} math={value} />,
                            math: ({value}) => <TeX className={classes.math} block math={value} />
                        }} allowDangerousHtml children={sectionInfo} />
                    : <CircularProgress />}
                </DialogContent>
                <DialogActions>
                    <Typography>
                        You can refer to these instructions at any time using the <HelpIcon fontSize="inherit" /> button at the top right corner of the questions page.
                        However, this won't pause the timer.
                    </Typography>
                    <Button size="large" variant="contained" color="secondary" onClick={handleDialogClose} className={classes.dialogDoneButton}>
                        Start
                    </Button>
                </DialogActions>
            </Dialog>
            <footer>
                <PaginationBar className={classes.paginationBar} />
            </footer>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    topAppBar: {
        position: 'fixed',
        top: 0
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    submitButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    timer: {
        position: 'fixed',
        zIndex: theme.zIndex.modal - 1,
        fallbacks: [
            {
                position: '-webkit-sticky'
            }
        ],
        top: 15,
        right: 10 
    },
    timerAppBar: {
        top: 80
    },
    questions: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '2000px',
        width: '100%',
        height: '100%'
    },
    extra: { 
        padding: theme.spacing(4),
    },
    dialog: {
        width: '100%'
    },
    dialogAppBar: {
        position: 'relative'
    },
    dialogTitle: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    dialogContent: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        "& iframe": {
            maxWidth: "100%",
            maxHeight: 500,
        },
        "& img": {
            maxWidth: "100%"
        }
    },
    math: {
        fontSize: '1.4em'
    },
    dialogDoneButton: {
        marginRight: theme.spacing(5),
        marginBottom: theme.spacing(2)
    },
    paginationBar: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        display: 'flex',
        alignItems: 'center'
    },
}));

export default Assessment;
