import React, { FC } from 'react';
import store, { RootState } from './store';
import { Provider, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from "react-router-dom";
import { CssBaseline, CircularProgress } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { HelmetProvider } from 'react-helmet-async';
import Firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/functions';
import { ReactReduxFirebaseProvider, isLoaded, isEmpty } from 'react-redux-firebase';
import Login from './routes/login';
import Assessment from './routes/assessment';
import AssessmentListing from './routes/assessment_listing';
import SectionListing from './routes/section_listing';
import Report from './routes/report';
import Admin from './routes/admin';
import Chart from "chart.js/auto";

// These are not meant to be secret, they can be checked in.
// They simply identify the associated Firebase project, not
// provide a means to authenticate to it.
Firebase.initializeApp({
    apiKey: "AIzaSyDMs9X67MPrlej4yw9Hn7axWcU5M73QNPI",
    authDomain: "career-assessment-a16ca.firebaseapp.com",
    databaseURL: "https://career-assessment-a16ca.firebaseio.com",
    projectId: "career-assessment-a16ca",
    storageBucket: "career-assessment-a16ca.appspot.com",
    messagingSenderId: "633067227301",
    appId: "1:633067227301:web:22667fe85be419abffa54c",
    measurementId: "G-845JCWKPQP"
});

Firebase.firestore().clearPersistence();

Firebase.firestore().enablePersistence({
    synchronizeTabs: true,
});

Chart.defaults.font.size = 40;

const AuthIsLoaded: FC = ({ children }) => {
    // @ts-ignore
    const auth = useSelector<RootState>(state => state.firebase.auth);

    if (!isLoaded(auth)) return <CircularProgress />;

    return <>{children}</>;
}

const PrivateRoute: FC<any> = ({ children, ...rest }) => {
    // @ts-ignore
    const auth = useSelector<RootState>(state => state.firebase.auth);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isEmpty(auth) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const App: FC = () => {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider
                firebase={Firebase}
                dispatch={store.dispatch}
                config={{ userProfile: 'userProfiles', useFirestoreForProfile: true }}>
                <HelmetProvider>
                    <StylesProvider injectFirst>
                        <CssBaseline />
                        <AuthIsLoaded>
                            <Switch>
                                <PrivateRoute path="/assessment-listing">
                                    <AssessmentListing />
                                </PrivateRoute>
                                <PrivateRoute path="/assessment/:assessmentId/:sectionId/:sectionName">
                                    <Assessment />
                                </PrivateRoute>
                                <PrivateRoute path="/assessment/:assessmentId">
                                    <SectionListing />
                                </PrivateRoute>
                                <PrivateRoute path="/report">
                                    <Report />
                                </PrivateRoute>
                                <PrivateRoute path="/admin">
                                    <Admin />
                                </PrivateRoute>
                                <Route path="/">
                                    <Login />
                                </Route>
                            </Switch>
                        </AuthIsLoaded>
                    </StylesProvider>
                </HelmetProvider>
            </ReactReduxFirebaseProvider>
        </Provider>
    );
}

export default App;
