import { RootState } from '../../store';
import { questionsAdapter } from './slice';
import * as R from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

export const questionsSliceSelector = (state: RootState) => state.questions;

export const {
    selectIds: questionsIdsSelector,
    selectAll: questionsDataSelector,
    selectTotal: totalQuestionsSelector,
    selectById: questionsByIdSelector,
} = questionsAdapter.getSelectors(questionsSliceSelector);

export const resultsSelector = createSelector(questionsSliceSelector, R.prop('results'));
