import React, { FC } from 'react';
import ContentRenderer from './contentRenderer';
import { QuestionData, AnswerData } from './types';
import { Radio, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export type FixedLayoutQuestionProps = {
    question: QuestionData
    onAnswerChange: (id: AnswerData['id']) => any,
    selection?: AnswerData['id']
}

const FixedLayoutQuestion: FC<FixedLayoutQuestionProps> = ({ question, onAnswerChange, selection }) => {
    const classes = useStyles();

    return (
        <>
            {question.content?.length > 0 ? 
                <div className={classes.question}>
                    {question.content?.map((content, index) => (
                        <ContentRenderer content={content} key={index} />
                    ))}
                </div> : null
            }
            {question.answers!.map((answer, idx) => (
                <Grid container alignItems="center" wrap="nowrap" key={answer.id}>
                    <Grid item>
                        <Typography variant="subtitle2">{String.fromCharCode(64 + (idx + 1))}</Typography>
                    </Grid>
                    <Grid item>
                        <Radio checked={selection ? selection === answer.id : false}
                               onClick={() => onAnswerChange(answer.id)} />
                    </Grid>
                    <Grid item>
                        {answer.content.map((content, index) => (
                            <ContentRenderer content={content} key={index} onClick={() => onAnswerChange(answer.id)} />
                        ))}
                    </Grid>
                </Grid>
            ))}
        </>
    );
}

const useStyles = makeStyles(theme => ({
    question: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

export default FixedLayoutQuestion;
