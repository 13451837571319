import React, { FC, useEffect, useRef, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Paper, Typography } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { produce } from "immer";
import { DragHandle as DragHandleIcon } from "@material-ui/icons";
import { QuestionData, AnswerData, RenderingHint } from './types';
import ContentRenderer from './contentRenderer';

export type ReorderQuestionProps = {
    question: QuestionData,
    onAnswerChange: (order: AnswerData['id'][]) => any,
    selection?: AnswerData['id'][]
}

const ReorderQuestion: FC<ReorderQuestionProps> = ({ question, onAnswerChange, selection }) => {
    const classes = useStyles();

    // useRef stores values across renders, so
    // this uuid value is initialized only once
    const droppableId = useRef(uuidv4());

    const preferColumn = useMemo(() => question.renderingHint.includes(RenderingHint.Column), [question.renderingHint]);

    const questionIds = useMemo(() => selection || question.answers!.map(R.prop('id')), [selection, question.answers]);

    const originalIdxs = useMemo(() => R.range(0, questionIds.length), [questionIds.length]);

    useEffect(() => {
        // This is so that the initial answer is submitted
        // if the user doesn't reorder the cards.
        onAnswerChange(questionIds);
    }, [onAnswerChange]);

    const onDragEnd = useCallback(({ source, destination }: DropResult) => {
        if (!destination) { return; }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        const item = originalIdxs[source.index];
        originalIdxs.splice(source.index, 1);
        originalIdxs.splice(destination.index, 0, item);

        onAnswerChange(
            produce(questionIds, draftItems => {
                const item = draftItems[source.index];
                draftItems.splice(source.index, 1);
                draftItems.splice(destination.index, 0, item);
            })
        );
    }, [onAnswerChange, questionIds, originalIdxs]);

    return (
        <>
            <div>
                {question.content?.map((content, index) => (
                    <ContentRenderer content={content} key={index} />
                ))}
                <div>
                    <p className={classes.most}>Most Liked</p>
                    <p className={classes.least}>Least Liked</p>
                </div>
                <div className={classes.scale} />
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`reorderQuestion-${droppableId.current}`} direction={preferColumn ? "vertical" : "horizontal"}>
                    {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Grid spacing={3} container direction={preferColumn ? "column" : "row"} justify="center" alignItems="center" wrap="nowrap">
                                {questionIds.map((id, index) => (
                                    <Draggable draggableId={id} index={index} key={id}>
                                        {(provided, snapshot) => (
                                            <Grid item
                                                innerRef={provided.innerRef}
                                                {...provided.draggableProps}>
                                                    <Paper
                                                        {...provided.dragHandleProps}
                                                        variant={snapshot.isDragging && !snapshot.isDropAnimating ? "elevation" : "outlined"}
                                                        elevation={5} className={classes.option}>
                                                        <Typography variant="subtitle2">{question.sequenceNumber}-{String.fromCharCode(64 + (originalIdxs[index] + 1))}</Typography>
                                                        <DragHandleIcon fontSize="large" />
                                                        {R.find(R.propEq('id', id), question.answers!)!.content.map((content, index) =>
                                                            <ContentRenderer content={content} key={index} />
                                                        )}
                                                    </Paper>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    scale: {
        height: '20px',
        margin: theme.spacing(2),
        backgroundImage: `linear-gradient(to right, ${theme.palette.success.dark}, ${theme.palette.error.dark})`
    },
    most: {
        margin: '0px !important',
        paddingLeft: theme.spacing(3),
        float: 'left',
        color: 'white',
    },
    least: {
        margin: '0px !important',
        paddingRight: theme.spacing(3),
        float: 'right',
        textAlign: 'right',
        color: 'white'
    },
    option: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

export default ReorderQuestion;
