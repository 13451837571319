import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { QuestionData, AnswerData, Result } from './types';

export type QuestionsSliceState = {
    ids: string[],
    entities: Record<NonNullable<QuestionData['id']>, QuestionData>,
    results: Record<NonNullable<QuestionData['id']>, Result | undefined>,
    isDirty: boolean,
}

export const questionsAdapter = createEntityAdapter<QuestionData>();
const reducerQuestionsSelector = questionsAdapter.getSelectors();

export const questionsSlice = createSlice({
    name: 'questions',
    initialState: {
        ...questionsAdapter.getInitialState({ results: {} }),
        isDirty: false,
    } as QuestionsSliceState,
    reducers: {
        changeAnswer(state, action: PayloadAction<{ questionId: QuestionData['id'],
                                                    answerIds: AnswerData['id'] | AnswerData['id'][] }>) {
            const { questionId, answerIds } = action.payload;

            if (!questionId) {
                return;
            }

            // if (!questionId || !reducerQuestionsSelector.selectIds(state).includes(questionId)) {
            //     return;
            // }

            state.isDirty = true;
            state.results[questionId] = answerIds;
        },
        newQuestions(state, action: PayloadAction<{ questions: QuestionData[],
                                                    results?: QuestionsSliceState["results"] }>) {
            const { questions, results } = action.payload;

            questionsAdapter.setAll(state, questions);

            state.isDirty = false;
            state.results = results || {};
        }
    }
});

export const { changeAnswer, newQuestions } = questionsSlice.actions;

export default questionsSlice.reducer;
