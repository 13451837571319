import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Radio, Typography, ButtonBase } from '@material-ui/core';
import { QuestionData, QuestionType, AnswerData, Result } from "./types";
import { decideQuestionComponent } from "./questionSet";
import { produce } from 'immer';
import ContentRenderer from './contentRenderer';

type GridQuestionProps = {
    question: QuestionData,
    onAnswerChange: (id: Result | undefined) => any,
    selection?: Result
}

const GridQuestion: FC<GridQuestionProps> = ({ question, onAnswerChange, selection }) => {
    const classes = useStyles();

    return (
        <Grid item xs container direction={question.type === QuestionType.Single ? "column" : "row"} justify="center" alignItems="center">
            <Grid item xs container direction="row" justify="center" alignItems="center" alignContent="center" className={classes.question}>
                {question.content?.map((content, index) =>
                    <ContentRenderer content={content} key={index} />
                )}
            </Grid>
            <Grid item xs container direction="row" justify="flex-start" alignItems="center" alignContent="center" spacing={2}>
                {question.type === QuestionType.Single ? (
                    <>
                        {question.answers!.map(({ id, content: contents }, idx) =>
                            <Grid item sm={4} key={id}>
                                <div className={classes.answerContainer}>
                                    <Typography variant="subtitle2">{String.fromCharCode(64 + (idx + 1))}</Typography>
                                    <Radio
                                        onClick={() => onAnswerChange(id)}
                                        checked={selection === id} />
                                    {contents.map((content, index) =>
                                        <ButtonBase key={index}
                                            onClick={() => onAnswerChange(id)} focusRipple={true}>
                                            <ContentRenderer content={content} />
                                        </ButtonBase>
                                    )}
                                </div>
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        {question.subquestions!.map((subquestion, idx) => {
                            const QuestionComponent = decideQuestionComponent(subquestion);

                            return (
                                <Grid item xs container direction="column" justify="center" alignItems="center" spacing={2} key={subquestion.id}>
                                    <Typography variant="subtitle2">{question.sequenceNumber}-{String.fromCharCode(64 + (idx + 1))}</Typography>
                                    <div className={classes.answerContainer}>
                                        {QuestionComponent ?
                                            <QuestionComponent question={subquestion}
                                                selection={selection ?
                                                    (selection as Record<NonNullable<QuestionData['id']>, AnswerData['id']>)[subquestion.id!] : undefined}
                                                onAnswerChange={(newAnswer: Result) => onAnswerChange(produce(selection || {}, draftSelection => {
                                                    // @ts-ignore
                                                    draftSelection[subquestion.id!] = newAnswer;
                                                }))} />
                                            : null}
                                    </div>
                                </Grid>
                            );
                        })}
                    </>
                )}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    question: {
        maxWidth: 'fit-content',
        marginBottom: theme.spacing(2),
        fallbacks: [{
            maxWidth: '-moz-fit-content'
        }, {
            maxWidth: '-webkit-intrinsic'
        }, {
            maxWidth: 'intrinsic'
        }]
    },
    answerContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default GridQuestion;
