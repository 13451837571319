import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, AppBar, Toolbar } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { useHotkeys } from 'react-hotkeys-hook';
import { currentPageSelector, totalPagesSelector } from './selectors';
import { changePage } from './slice';

export interface PaginationBarProps {
    className?: string
}

const PaginationBar: FC<PaginationBarProps> = ({ className }) => {
    const classes = useStyles();
    const theme = useTheme();

    const dispatch = useDispatch();

    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const currentPage = useSelector(currentPageSelector);
    const totalPages = useSelector(totalPagesSelector);

    useHotkeys('left,pageup,h', () => {
        dispatch(changePage({ currentPage, newPage: currentPage - 1 }));
    }, [currentPage]);

    useHotkeys('right,pagedown,l', () => {
        dispatch(changePage({ currentPage, newPage: currentPage + 1 }));
    }, [currentPage]);

    return (
        <AppBar color="inherit" className={className || ""}>
            <Toolbar>
                <Pagination
                    shape="rounded"
                    size={mdUp ? "large" : "medium"}
                    siblingCount={1}
                    renderItem={(item) => {
                        const isPageItem = !['first', 'last', 'next', 'previous'].includes(item.type);

                        return (
                            <PaginationItem
                                className={ isPageItem ? classes.questionPaginationItem : classes.questionPaginationButton }
                                {...Object.assign(item, { color: isPageItem ? "primary" : "secondary" })}
                                />
                        );
                    }}
                    count={totalPages}
                    page={currentPage}
                    onChange={(_, newPage) => {
                        dispatch(changePage({ currentPage, newPage }))
                        window.scrollTo(0, 0);
                    }} />
            </Toolbar>
        </AppBar>
    );
}

const useStyles = makeStyles((theme) => ({
    questionPaginationItem: {
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(2)
        }
    },
    questionPaginationButton: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default PaginationBar;
