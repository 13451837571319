import React, { FC, useMemo } from 'react';
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ContentRenderer from './contentRenderer';
import { QuestionData, AnswerData, RenderingHint } from './types';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

export type CategorizationQuestion = {
    question: QuestionData,
    onAnswerChange: (id: AnswerData['id'] | undefined) => any,
    selection?: AnswerData['id'] | undefined
};

const CategorizationQuestion: FC<CategorizationQuestion> = ({ question, onAnswerChange, selection }) => {
    const classes = useStyles();

    const preferColumn = useMemo(
        () => question.renderingHint.includes(RenderingHint.Column),
        [question.renderingHint]
    );

    return (
        <Grid item xs container direction={preferColumn ? "column" : "row"} justify="center" alignItems="center" spacing={2}>
            {question.content?.map((content, index) => (
                <Grid item key={index}>
                    <ContentRenderer content={content} />
                </Grid>
            ))}
            <Grid item>
                <ToggleButtonGroup value={selection} exclusive
                    onChange={(_, newSelection) =>
                        onAnswerChange(newSelection)
                    }>
                    {question.answers!.map(answer => (
                        <ToggleButton size="small" value={answer.id} key={answer.id}
                            className={clsx({[classes.selected]: selection === answer.id})}>
                            {answer.content.map((content, index) => (
                                    <ContentRenderer content={content} key={index} />
                            ))}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: theme.palette.primary.dark
    }
}));

export default CategorizationQuestion;
